/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    table: "table",
    thead: "thead",
    tr: "tr",
    th: "th",
    a: "a",
    h2: "h2",
    tbody: "tbody",
    td: "td",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#123"
  }, "#123")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#a"
  }, "A")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#b"
  }, "B")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#c"
  }, "C")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#d"
  }, "D")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#e"
  }, "E")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#f"
  }, "F")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#g"
  }, "G")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#h"
  }, "H")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#i"
  }, "I")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#j"
  }, "J")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#k"
  }, "K")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#l"
  }, "L")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#m"
  }, "M")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#n"
  }, "N")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#o"
  }, "O")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#p"
  }, "P")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#q"
  }, "Q")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#r"
  }, "R")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#s"
  }, "S")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#t"
  }, "T")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#u"
  }, "U")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#v"
  }, "V")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#w"
  }, "W")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#x"
  }, "X")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#y"
  }, "Y")), React.createElement(_components.th, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#z"
  }, "Z"))))), "\n", React.createElement(_components.h2, null, "123"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "3-D Secure")), React.createElement(_components.td, {
    align: "left"
  }, "Security protocol for online credit and debit transactions.")))), "\n", React.createElement(_components.h2, null, "A"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Acquirer")), React.createElement(_components.td, {
    align: "left"
  }, "Merchant’s bank that accepts funds from transactions. Also known as the acquiring bank.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Acquirer Reference Number (ARN)")), React.createElement(_components.td, {
    align: "left"
  }, "Unique 23-digit number that the acquirer assigns to a Visa or Mastercard payment or refund. The acquirer uses the ARN to track the transaction as it transfers between the merchant and the cardholder.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Address Verification Service (AVS)")), React.createElement(_components.td, {
    align: "left"
  }, "Service that checks whether the billing address associated with the transaction matches the billing address that the issuing bank has stored for the card. Financial institutions use this service to detect suspicious or fraudulent transactions.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "American Banker's Association (ABA) number")), React.createElement(_components.td, {
    align: "left"
  }, "For US checks, this is the unique identifier of the financial institution. Also known as the ABA routing number. We use the ABA routing number and the ", React.createElement(_components.a, {
    href: "#d"
  }, "Demand Deposit Account (DDA)"), " to identify individual bank accounts.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Application Programming Interface (API)")), React.createElement(_components.td, {
    align: "left"
  }, "Software that different computer programs use to communicate with each other. For example, ", React.createElement(_components.a, {
    href: "#p"
  }, "Point of Sale (POS)"), " devices communicate with our ", React.createElement(_components.a, {
    href: "#p"
  }, "payment gateway"), " using our API.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Attestation of Compliance (AOC)")), React.createElement(_components.td, {
    align: "left"
  }, "Declaration of an organization’s compliance with ", React.createElement(_components.a, {
    href: "#p"
  }, "Payment Card Industry Data Security Standard (PCI DSS)"), ".")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Authorization")), React.createElement(_components.td, {
    align: "left"
  }, "Process that places a temporary hold on funds in a customer’s account. For example, a hotel can reserve a dollar amount on a customer’s card at check-in to cover future additional costs such as room service. The hotel then captures the actual final amount that the customer spent when they check out.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Authorized signatory")), React.createElement(_components.td, {
    align: "left"
  }, "Payroc term.", React.createElement("br"), React.createElement("br"), "Type of ", React.createElement(_components.a, {
    href: "#o"
  }, "owner"), " that can make decisions for the processing account. They don't have an equity stake in the business.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Automated Clearing House (ACH)")), React.createElement(_components.td, {
    align: "left"
  }, "Method that U.S. financial institutions use to electronically send funds between bank accounts.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Autosave")), React.createElement(_components.td, {
    align: "left"
  }, "Payroc product. ", React.createElement("br"), React.createElement("br"), "When a merchant runs a credit card sale, we send a percentage of the funds to a savings account. The merchant sets the percentage that they want to contribute from each sale.")))), "\n", React.createElement(_components.h2, null, "B"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "B2B")), React.createElement(_components.td, {
    align: "left"
  }, "Business to business.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "B2C")), React.createElement(_components.td, {
    align: "left"
  }, "Business to consumer.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Bank Identification Number (BIN)")), React.createElement(_components.td, {
    align: "left"
  }, "First six digits on a payment card. The BIN identifies the specific financial institution that issued the card.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Bank Identifier Code, Business Identifier Code, Bank International Code (BIC)")), React.createElement(_components.td, {
    align: "left"
  }, "Unique identifier for a bank. Also known as a ", React.createElement(_components.a, {
    href: "#s"
  }, "SWIFT code"), ". The terms “BIC” and “SWIFT” are often used interchangeably.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Bank Secrecy Act (BSA)")), React.createElement(_components.td, {
    align: "left"
  }, "U.S. law that requires financial institutions to assist government agencies to detect and prevent money laundering.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Basis point (BP)")), React.createElement(_components.td, {
    align: "left"
  }, "Basis points are a unit of measurement for rates in finance. One basis point is equal to 0.01%. For example, if you apply 10bp to $1,000,000, this is equal to $1,000. Basis points are sometimes referred to as a \"bip\" or a \"beep\".")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Batch")), React.createElement(_components.td, {
    align: "left"
  }, "Collection of card transactions.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Bearer token")), React.createElement(_components.td, {
    align: "left"
  }, "Access token that we use to authenticate requests sent to our API.")))), "\n", React.createElement(_components.h2, null, "C"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Canadian Payments Association (CPA)")), React.createElement(_components.td, {
    align: "left"
  }, "Canadian organization that manages the national system to clear and settle payments.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Capture")), React.createElement(_components.td, {
    align: "left"
  }, "Process that moves pre-authorized funds to the merchant. For example, a hotel reserved a dollar amount on a customer’s card at check-in to cover future additional costs such as room service. The hotel then captures the actual final amount that the customer spent when they check out.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Card Not Present (CNP)")), React.createElement(_components.td, {
    align: "left"
  }, "Type of payment that occurs when the card isn’t physically present at the point of sale. For example, a cardholder buys an item from an online store.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Card Verification Value (CVV)")), React.createElement(_components.td, {
    align: "left"
  }, "Three-digit or four-digit code that an issuing bank prints on credit cards and debit cards. For card-not-present transactions, cardholders provide this code to verify that they have access to the card. You’re not allowed to store the CVV for future transactions.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Cash discounting")), React.createElement(_components.td, {
    align: "left"
  }, "Type of pricing program that merchants can use to help offset the cost of processing fees. If the merchant uses this type of pricing program, they add an additional cost to all goods or services that they provide. The POS then removes the additional cost as a discount when a customer pays with cash.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Cash rewards")), React.createElement(_components.td, {
    align: "left"
  }, "Cash Discounting program that Payroc offers to merchants.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Cash advance")), React.createElement(_components.td, {
    align: "left"
  }, "Cash advance can refer to:", React.createElement("br"), "– A high interest, short-term loan from a bank or lender.  ", React.createElement("br"), "– The withdrawal of cash from a credit card account.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Certified Anti Money Laundering Specialist (CAMS)")), React.createElement(_components.td, {
    align: "left"
  }, "Certified professional who investigates money laundering.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Chargeback")), React.createElement(_components.td, {
    align: "left"
  }, "Payment that is returned to a cardholder after they successfully dispute the payment through their issuing bank. For example, if the cardholder doesn’t receive the goods that they paid for, the cardholder can open a dispute to recover the cost of the goods.", React.createElement("br"), React.createElement("br"), "A chargeback is not the same as a refund. A chargeback must follow a dispute and must involve the cardholder’s issuing bank.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Chargeback Reversal")), React.createElement(_components.td, {
    align: "left"
  }, "Reversal of a previous chargeback. For example, if the merchant provides evidence that the disputed transaction was valid.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Check 21")), React.createElement(_components.td, {
    align: "left"
  }, "U.S. law that allows banks to create and send digital copies of checks.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Clearing")), React.createElement(_components.td, {
    align: "left"
  }, "Process of moving funds from the customer’s account to the merchant’s account.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Consumer Choice")), React.createElement(_components.td, {
    align: "left"
  }, "Cash discounting program that Payroc offers to merchants.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Control prong")), React.createElement(_components.td, {
    align: "left"
  }, "Payroc term. ", React.createElement("br"), React.createElement("br"), "Type of ", React.createElement(_components.a, {
    href: "#o"
  }, "owner"), " that can make decisions for the processing account. They have an equity stake in the business.")))), "\n", React.createElement(_components.h2, null, "D"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Debit network")), React.createElement(_components.td, {
    align: "left"
  }, "Network that processes debit transactions between the cardholder and merchant. Examples of debit networks include Visa, Mastercard, and Star.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Demand Deposit Account (DDA)")), React.createElement(_components.td, {
    align: "left"
  }, "ID number on US checks that identifies the customer’s bank account. Also known as an account number.", React.createElement("br"), "We use the ", React.createElement(_components.a, {
    href: "#a"
  }, "ABA routing number"), " and the Demand Deposit Account (DDA) to identify individual bank accounts.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Dispute")), React.createElement(_components.td, {
    align: "left"
  }, "Transaction dispute that occurs when a cardholder makes a formal complaint against a merchant about a specific transaction. The cardholder normally registers the dispute through their issuing bank to begin the chargeback process.", React.createElement("br"), React.createElement(_components.strong, null, "Note"), ": Visa uses the term \"dispute\" to refer to a chargeback.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Doing business as (DBA)")), React.createElement(_components.td, {
    align: "left"
  }, "Public-facing name of a business. Also known as an assumed name, a fictitious business name, or a trade name.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Downgrade")), React.createElement(_components.td, {
    align: "left"
  }, "When a payment processor sends a transaction through the interchange network, it assigns it to an interchange category. If the transaction doesn’t meet the requirements for that interchange category, it is re-routed, and charged a higher interchange fee. This is known as a downgrade.", React.createElement("br"), React.createElement("br"), " There are many reasons a transaction can be downgraded, for example, if there is an ", React.createElement(_components.a, {
    href: "#a"
  }, "Address Verification Service (AVS)"), " mismatch.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Dynamic Currency Conversion (DCC)")), React.createElement(_components.td, {
    align: "left"
  }, "Service that provides cardholders with the choice to pay in either their own currency or the merchant’s currency. ", React.createElement("br"), "For example, when a cardholder purchases goods when they travel abroad.")))), "\n", React.createElement(_components.h2, null, "E"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Easy pay")), React.createElement(_components.td, {
    align: "left"
  }, "Payroc product. ", React.createElement("br"), React.createElement("br"), "Merchants can direct a portion of funds in each batch to a recipient of their choice. They can also set a limit to the amount that they send.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "eCommerce")), React.createElement(_components.td, {
    align: "left"
  }, "Buying or selling goods or services through the Internet.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Effective Merchant Discount Rate (EMDR)")), React.createElement(_components.td, {
    align: "left"
  }, "Percentage of total sales volume that a merchant pays as fees to their acquirer. See also ", React.createElement(_components.a, {
    href: "#m"
  }, "Merchant Discount Rate (MDR)"), ".")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Electronic Benefits Transfer (EBT)")), React.createElement(_components.td, {
    align: "left"
  }, "Card that the U.S. government issues to people eligible for benefits.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Electronic Funds Transfer (EFT)")), React.createElement(_components.td, {
    align: "left"
  }, "Term that describes the electronic movement of funds from one account to another.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Employer Identification Number (EIN)")), React.createElement(_components.td, {
    align: "left"
  }, "Unique nine-digit number that the Internal Revenue Service assigns to U.S. businesses. Also known as a ", React.createElement(_components.a, {
    href: "#t"
  }, "Federal Tax Identification Number"), ".")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Encryption")), React.createElement(_components.td, {
    align: "left"
  }, "Process of protecting information by transforming it into a format that third parties can’t read. Only the intended recipient of the information can read it.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "End-to-end Encryption (E2EE)")), React.createElement(_components.td, {
    align: "left"
  }, "Type of encryption that protects communication between two devices. The sending device encrypts the data and only the intended recipient device can decrypt it. Third parties are unable to access the sent data. See also ", React.createElement(_components.a, {
    href: "#e"
  }, "encryption"), ".")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Equipment Request Form (ERF)")), React.createElement(_components.td, {
    align: "left"
  }, "Form that merchants use to request processing equipment from us.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Europay, Mastercard, Visa (EMV)")), React.createElement(_components.td, {
    align: "left"
  }, "EMV can refer to:", React.createElement("br"), React.createElement("br"), "– EMV technology, such as the chip on a credit card or debit card.", React.createElement("br"), "– EMVco, the company that manages EMV specifications.")))), "\n", React.createElement(_components.h2, null, "F"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Federal Trade Commission (FTC)")), React.createElement(_components.td, {
    align: "left"
  }, "Enforces consumer protection laws in commerce.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "FinTech")), React.createElement(_components.td, {
    align: "left"
  }, "Financial technology. Technology that supports banking and financial services.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Financial Institutions File (FIF)")), React.createElement(_components.td, {
    align: "left"
  }, "Electronic directory of routing numbers of financial institutions in Canada.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Fixed Acquirer Network Fee (FANF)")), React.createElement(_components.td, {
    align: "left"
  }, "Fee that Visa charges to all businesses that accept Visa cards.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Fluidpay")), React.createElement(_components.td, {
    align: "left"
  }, "Third-party payment gateway.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Food and Consumer Service Number (FCS number)")), React.createElement(_components.td, {
    align: "left"
  }, "Unique identifier that the Department of Agriculture assigns to a merchant that accepts ", React.createElement(_components.a, {
    href: "#e"
  }, "EBT"), " transactions. Also known as a Food and Nutrition Service (FNS) number.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Food and Nutrition Service Number (FNS number)")), React.createElement(_components.td, {
    align: "left"
  }, "Unique identifier that the Department of Agriculture assigns to a merchant that accepts ", React.createElement(_components.a, {
    href: "#e"
  }, "EBT"), " transactions. Also known as a Food and Consumer Service (FCS) number.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Fund reversals")), React.createElement(_components.td, {
    align: "left"
  }, "The return of funds to the cardholder’s account. This can be a ", React.createElement(_components.a, {
    href: "#r"
  }, "refund"), ", a payment ", React.createElement(_components.a, {
    href: "#r"
  }, "reversal"), ", or a ", React.createElement(_components.a, {
    href: "#c"
  }, "chargeback"), ". The cardholder, ", React.createElement(_components.a, {
    href: "#m"
  }, "merchant"), ", ", React.createElement(_components.a, {
    href: "#i"
  }, "issuing bank"), ", ", React.createElement(_components.a, {
    href: "#a"
  }, "acquiring bank"), ", or card network can initiate a fund reversal.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Funding account")), React.createElement(_components.td, {
    align: "left"
  }, "Bank account details that a funding recipient or a merchant uses to receive funds. ", React.createElement("br"), " ", React.createElement(_components.strong, null, "Note"), ": A funding account is not the same as a ", React.createElement(_components.a, {
    href: "#p"
  }, "processing account"), ". A funding account does not process transactions.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Funding activity")), React.createElement(_components.td, {
    align: "left"
  }, "History of events associated with a funding account. For example, details of funds that were sent to the funding account.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Funding balance")), React.createElement(_components.td, {
    align: "left"
  }, "Available funds that you can send to funding accounts.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Funding instruction")), React.createElement(_components.td, {
    align: "left"
  }, "Instructions that we use to send funds to funding accounts.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Funding recipient")), React.createElement(_components.td, {
    align: "left"
  }, "Entity that can receive funds but doesn't process transactions, for example, a charity.")))), "\n", React.createElement(_components.h2, null, "G"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Gateway")), React.createElement(_components.td, {
    align: "left"
  }, "See ", React.createElement(_components.a, {
    href: "#p"
  }, "Payment Gateway"), ".")))), "\n", React.createElement(_components.h2, null, "H"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Health Insurance Portability and Accountability Act (HIPAA)")), React.createElement(_components.td, {
    align: "left"
  }, "U.S. law that covers how to protect patient information. Mandatory for payments in healthcare industry.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Hosted Payment Page (HPP)")), React.createElement(_components.td, {
    align: "left"
  }, "Page that a third party hosts for secure payment on an eCommerce site. ", React.createElement("br"), React.createElement("br"), " For example, a customer clicks the checkout button on an ", React.createElement(_components.a, {
    href: "#e"
  }, "eCommerce"), " site, and the site redirects them to the hosted payment page to complete their purchase. Also known as a hosted ", React.createElement(_components.a, {
    href: "#p"
  }, "payment gateway"), ".")))), "\n", React.createElement(_components.h2, null, "I"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Idempotency")), React.createElement(_components.td, {
    align: "left"
  }, "For API requests, an operation that has no additional effect if it is called more than once with the same input parameters. For example for payments, idempotency prevents duplicate transactions.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Identity service")), React.createElement(_components.td, {
    align: "left"
  }, "Our API endpoint that issues bearer tokens.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Independent Sales Organization (ISO)")), React.createElement(_components.td, {
    align: "left"
  }, "Company that sells credit card processing services independently from a financial firm or bank. See also Independent Sales Group (ISG). ", React.createElement("br"), "Also known as a Merchant Service Provider (MSP).")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Independent Software Vendor (ISV)")), React.createElement(_components.td, {
    align: "left"
  }, "Organization that creates and sells software.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Interac")), React.createElement(_components.td, {
    align: "left"
  }, "Canada’s network for debit cards.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Interbank Card Association number (ICA)")), React.createElement(_components.td, {
    align: "left"
  }, "Unique identifier that MasterCard uses to identify issuers and acquirers.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Interchange fee")), React.createElement(_components.td, {
    align: "left"
  }, "Fee that card brands charge to merchants when a customer pays by credit card or debit card.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Interchange network")), React.createElement(_components.td, {
    align: "left"
  }, "Electronic networks that card brands manage that exchange card transaction data between issuers and acquirers. See also ", React.createElement(_components.a, {
    href: "/knowledge/card-payments/interchange"
  }, "interchange fee"), ".")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "International Bank Account Number (IBAN)")), React.createElement(_components.td, {
    align: "left"
  }, "Unique identifier of a customer’s bank account.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Internet Protocol (IP) address")), React.createElement(_components.td, {
    align: "left"
  }, "Numerical label that identifies a network device on a computer network.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Issuing Bank")), React.createElement(_components.td, {
    align: "left"
  }, "Financial institution that provides a debit card or a credit card to a cardholder. Also known as an issuer or card issuer.")))), "\n", React.createElement(_components.h2, null, "J"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Japan Credit Bureau (JCB)")), React.createElement(_components.td, {
    align: "left"
  }, "Major credit network in Japan.")))), "\n", React.createElement(_components.h2, null, "K"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition")))), "\n", React.createElement(_components.h2, null, "L"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Legal Business Name (LBN)")), React.createElement(_components.td, {
    align: "left"
  }, "Name used by a business for government and legal matters. Often different from the trade name, or ", React.createElement(_components.a, {
    href: "#d"
  }, "DBA"), ".")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Level 1 card data")), React.createElement(_components.td, {
    align: "left"
  }, "Information about a transaction. Level 1 is the most basic level and requires only standard card information. Level 1 payments include the following information: ", React.createElement("br"), React.createElement("br"), "– Card number", React.createElement("br"), "– CVV code", React.createElement("br"), "– Total purchase amount", React.createElement("br"), "– Merchant name", React.createElement("br"), React.createElement("br"), "Each card network can require different information for  apayment to qualify for level 1, 2, or 3.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Level 2 card data")), React.createElement(_components.td, {
    align: "left"
  }, "Additional information about a transaction. Level 2 payments include the following information:", React.createElement("br"), React.createElement("br"), "– All information from Level 1", React.createElement("br"), "– Purchase Order number", React.createElement("br"), "– Destination ZIP", React.createElement("br"), "– Tax indicator", React.createElement("br"), "– Tax amount", React.createElement("br"), "– Requestor name", React.createElement("br"), "– Destination address", React.createElement("br"), "– Destination city", React.createElement("br"), "– Destination state", React.createElement("br"), "– VAT ID", React.createElement("br"), React.createElement("br"), "Each card network requires different information for a payment to qualify for level 1, 2, or 3.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Level 3 card data")), React.createElement(_components.td, {
    align: "left"
  }, "Additional information about a transaction. Level 3 payments include the following information:", React.createElement("br"), React.createElement("br"), "– All information from Level 2", React.createElement("br"), "– Item ID or SKU", React.createElement("br"), "– Unit price", React.createElement("br"), "– Extended price", React.createElement("br"), "– Unit of measure ", React.createElement("br"), "– Unit of cost", React.createElement("br"), "– Commodity code", React.createElement("br"), "– Quantity", React.createElement("br"), "– Discount amount, per line item and total", React.createElement("br"), "– Shipping or shipping amount", React.createElement("br"), "– VAT information", React.createElement("br"), "– Debit or credit indicator ", React.createElement("br"), React.createElement("br"), "Each card network requires different information for a transaction to qualify for level 1, 2, or 3.")))), "\n", React.createElement(_components.h2, null, "M"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Magnetic Stripe Reader (MSR)")), React.createElement(_components.td, {
    align: "left"
  }, "Device that reads the raw data on a card’s magnetic stripe.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Mail Order/Telephone Order (MOTO)")), React.createElement(_components.td, {
    align: "left"
  }, "Payment through written request or over-the-phone. To run the payment, the merchant enters the card data into a device or a ", React.createElement(_components.a, {
    href: "#v"
  }, "virtual terminal"), ".")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Metadata")), React.createElement(_components.td, {
    align: "left"
  }, "Data that describes data. Using our API, you can add metadata to certain requests – for more information about how to add metadata to your API requests, see ", React.createElement(_components.a, {
    href: "https://docs.payroc.com/api/metadata."
  }, "Metadata"))), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Merchant")), React.createElement(_components.td, {
    align: "left"
  }, "Entity that accepts payments.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Merchant agreement")), React.createElement(_components.td, {
    align: "left"
  }, "Contract between a merchant and an acquirer that defines their respective rights, responsibilities, and warranties related to the processing of payment transactions.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Merchant Discount Rate (MDR)")), React.createElement(_components.td, {
    align: "left"
  }, "Percentage fee that the payment processor charges to a merchant for each debit card transaction or credit card transaction.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Merchant Identification Number (MID)")), React.createElement(_components.td, {
    align: "left"
  }, "Unique identifier for an individual merchant. Every active merchant with a processing account has a MID.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Merchant Initiated Transaction (MIT)")), React.createElement(_components.td, {
    align: "left"
  }, "Payment that the merchant initiates instead of the customer. MITs are subject to a MIT agreement between the merchant and the customer. MITs are often used for recurring payments, for example, a monthly subscription to a streaming service.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Merchant of Record (MOR)")), React.createElement(_components.td, {
    align: "left"
  }, "Entity that sells goods or services to a customer. This is the name that appears on the customer’s credit card statement.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Merchant Processing Application (MPA)")), React.createElement(_components.td, {
    align: "left"
  }, "Application form that merchants complete to process payments with Payroc.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Merchant of Record (MOR)")), React.createElement(_components.td, {
    align: "left"
  }, "Entity that sells goods or services to a customer. This is the name that appears on the customer’s credit card statement.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Money Express")), React.createElement(_components.td, {
    align: "left"
  }, "Payroc service for next-day merchant funding. For an additional monthly fee, we deposit funds to the merchant account the next day.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Money Express Prime")), React.createElement(_components.td, {
    align: "left"
  }, "Payroc service for same-day merchant funding. For an additional monthly fee, we deposit funds to the merchant account the same day.")))), "\n", React.createElement(_components.h2, null, "N"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "National Automated Clearing House Association (NACHA)")), React.createElement(_components.td, {
    align: "left"
  }, "Non-profit association that governs the ", React.createElement(_components.a, {
    href: "#a"
  }, "Automated Clearing House (ACH)"), " network.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Near Field Communication (NFC)")), React.createElement(_components.td, {
    align: "left"
  }, "Technology that devices use to wirelessly send information to other NFC devices. Devices use NFC for contactless payments.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Network Access Brand Usage (NABU)")), React.createElement(_components.td, {
    align: "left"
  }, "Fee that MasterCard charges to the ", React.createElement(_components.a, {
    href: "#a"
  }, "acquirer"), " for using their network. See also ", React.createElement(_components.a, {
    href: "#f"
  }, "Fixed Acquirer Network Fee (FANF)"), ".")))), "\n", React.createElement(_components.h2, null, "O"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Office of Foreign Assets Control (OFAC)")), React.createElement(_components.td, {
    align: "left"
  }, "U.S. government body that administers and enforces economic and trade sanctions against targeted foreign governments, individuals, groups, and entities.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Optical Character Recognition (OCR)")), React.createElement(_components.td, {
    align: "left"
  }, "In payments, refers to a mobile device’s ability to read alphanumeric characters on a physical card or a check.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Originating Depository Financial Institution (ODFI)")), React.createElement(_components.td, {
    align: "left"
  }, "Financial institution that helps to transmit ", React.createElement(_components.a, {
    href: "#a"
  }, "ACH"), " payments to the Federal Reserve or the ACH network.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Owners")), React.createElement(_components.td, {
    align: "left"
  }, "Payroc term. ", React.createElement("br"), React.createElement("br"), React.createElement(_components.strong, null, "Owner for a processing account"), React.createElement("br"), "An individual that owns the relationship between Payroc and the processing account. An owner for a processing account can be a ", React.createElement(_components.a, {
    href: "#c"
  }, "control prong"), " or ", React.createElement(_components.a, {
    href: "#a"
  }, "authorized signatory"), ".", React.createElement("br"), React.createElement("br"), React.createElement(_components.strong, null, "Owner for a funding recipient"), React.createElement("br"), "An individual that is associated with the funding recipient.")))), "\n", React.createElement(_components.h2, null, "P"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Pagination")), React.createElement(_components.td, {
    align: "left"
  }, "Limits the number of results that we return in response to a GET request.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Payment Card Industry Data Security Standard (PCI DSS)")), React.createElement(_components.td, {
    align: "left"
  }, "Data security requirements that the Payment Card Industry Security Standards Council (PCI SSC) created to protect cardholder data. PCI DSS applies to all entities that process, store, or transmit cardholder data and sensitive authentication data. Entities that meet the requirements are PCI compliant.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Payment Card Industry Security Standards Council (PCI SSC)")), React.createElement(_components.td, {
    align: "left"
  }, "Organization that designs and upholds Payment Card Industry Data Security Standard (PCI DSS).")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Payment facilitator")), React.createElement(_components.td, {
    align: "left"
  }, "Entity that provides software and services to merchants so that they can run payments without the need to apply to an acquiring bank for an account.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Payment gateway")), React.createElement(_components.td, {
    align: "left"
  }, "Infrastructure that securely sends payment data to a processor.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Payment plan")), React.createElement(_components.td, {
    align: "left"
  }, "Template that describes how the merchant takes repeat payments from their customers. Merchants use a subscription to set up recurring payments for a customer.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Payment Processor")), React.createElement(_components.td, {
    align: "left"
  }, "Entity that communicates with ", React.createElement(_components.a, {
    href: "#i"
  }, "issuing banks"), " to authorize and settle transactions.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Payroc Insights (Formerly Partner Engage)")), React.createElement(_components.td, {
    align: "left"
  }, "Payroc’s portal that hosts  transaction reporting data and documents, for example, Schedule A, merchant statements, and 1099 tax forms.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Payroc Payment System (PPS)")), React.createElement(_components.td, {
    align: "left"
  }, "Internal system that we use to manage merchants and payments.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Peer-to-peer Payments (P2P)")), React.createElement(_components.td, {
    align: "left"
  }, "Payments directly between cardholders.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Personally Identifiable Information (PII)")), React.createElement(_components.td, {
    align: "left"
  }, "Information that can be used to identify an individual.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Personal Identification Number (PIN)")), React.createElement(_components.td, {
    align: "left"
  }, "Number that the cardholder enters into the device to verify that they are the legitimate cardholder.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Per trans")), React.createElement(_components.td, {
    align: "left"
  }, "Set fee that applies to each transaction.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Point of Sale (POS)")), React.createElement(_components.td, {
    align: "left"
  }, "Device or software that merchants use to run transactions.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Point to Point Encryption (P2PE)")), React.createElement(_components.td, {
    align: "left"
  }, "Encryption standard established by the ", React.createElement(_components.a, {
    href: "#p"
  }, "Payment Card Industry Security Standards Council (PCI SSC)"), ".")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Pre-authorization")), React.createElement(_components.td, {
    align: "left"
  }, "Temporary hold placed on a customer's account to ensure the availability of funds, such as at check-in for a hotel stay.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Pre-Authorized debit (PAD)")), React.createElement(_components.td, {
    align: "left"
  }, "Canadian payment system. A recipient or merchant can use PAD payments to withdraw money from a bank account when a payment is due. Commonly used for monthly payments. The account holder must complete a Pre-Authorized Debit Agreement to set up the recurring payments.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Primary Account Number (PAN)")), React.createElement(_components.td, {
    align: "left"
  }, "Cardholder's unique credit card or debit card number, also known as account number or long card number.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Processing account")), React.createElement(_components.td, {
    align: "left"
  }, "Type of account that a merchant uses to run transactions for the business. Each processing account is linked to a merchant platform. ", React.createElement("br"), " ", React.createElement(_components.strong, null, "Note"), ": A processing account is not the same as a ", React.createElement(_components.a, {
    href: "#f"
  }, "funding account"), ".")))), "\n", React.createElement(_components.h2, null, "Q"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }), React.createElement(_components.td, {
    align: "left"
  })))), "\n", React.createElement(_components.h2, null, "R"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Real-time Payments (RTP)")), React.createElement(_components.td, {
    align: "left"
  }, "Payments between bank accounts that are initiated, cleared, and settled within seconds.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Receiving Depository Financial Institution (RDFI)")), React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.a, {
    href: "#n"
  }, "NACHA-recognized"), " bank or credit union that can receive ", React.createElement(_components.a, {
    href: "#a"
  }, "ACH"), " payments.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Recurring payments")), React.createElement(_components.td, {
    align: "left"
  }, "Payment that occurs on a regular basis, for example, a ", React.createElement(_components.a, {
    href: "#s"
  }, "subscription"), " fee.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Registered Independent Sales Organization (Registered ISO)")), React.createElement(_components.td, {
    align: "left"
  }, "Independent company that sells for a Merchant Acquirer such as Payroc. See also ", React.createElement(_components.a, {
    href: "#i"
  }, "Independent Sales Organization (ISO)"), ".")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Refund")), React.createElement(_components.td, {
    align: "left"
  }, "Payment that the merchant returns to the customer if the payment is in a settled batch. If the merchant has not settled the ", React.createElement(_components.a, {
    href: "#b"
  }, "batch"), " that contains the payment, they must reverse the payment instead.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Remote Deposit Capture")), React.createElement(_components.td, {
    align: "left"
  }, "Method that financial institutions use to scan paper checks and electronically process them.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Refund")), React.createElement(_components.td, {
    align: "left"
  }, "Payment that the merchant returns to the customer if the payment is in a settled ", React.createElement(_components.a, {
    href: "#b"
  }, "batch"), ". If the merchant has not settled the batch that contains the payment, they must ", React.createElement(_components.a, {
    href: "#r"
  }, "reverse"), " the payment instead.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Reversal (void)")), React.createElement(_components.td, {
    align: "left"
  }, "Payment that the merchant returns to the customer if the payment is in an open batch. If the merchant has settled the ", React.createElement(_components.a, {
    href: "#b"
  }, "batch"), ", they must ", React.createElement(_components.a, {
    href: "#r"
  }, "refund"), " the payment instead.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Reward pay, Reward pay choice")), React.createElement(_components.td, {
    align: "left"
  }, "Payroc pricing programs that pass processing fees to the customer.")))), "\n", React.createElement(_components.h2, null, "S"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Same Day Funding")), React.createElement(_components.td, {
    align: "left"
  }, "Service that allows a merchant to close and settle a batch as late as 4:00 CDT and to receive the funds of the settled transactions the same business day.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Self-Assessment Questionnaire (SAQ)")), React.createElement(_components.td, {
    align: "left"
  }, "Questionnaire that merchants complete to verify that they are ", React.createElement(_components.a, {
    href: "#p"
  }, "PCI-DSS"), " compliant.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Settlement")), React.createElement(_components.td, {
    align: "left"
  }, "Process of paying authorized funds to merchants. ", React.createElement("br"), React.createElement("br"), "The settlement process includes the following steps:", React.createElement("br"), "1. A merchant closes and submits a batch of transactions to the payment processor.", React.createElement("br"), "2. The payment processor sends the transaction data to the card brand.", React.createElement("br"), "3. The payment processor sends the funds from the transaction to the merchant's bank account. ", React.createElement("br"), "4. The card brand settles the batch of transactions and issues the funds to the payment processor.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Single-use token")), React.createElement(_components.td, {
    align: "left"
  }, "A ", React.createElement(_components.a, {
    href: "#t"
  }, "token"), " that  represents a cardholder’s payment details. A single-use token has a 30-minute expiry and can only be used once.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Social security number (SSN)")), React.createElement(_components.td, {
    align: "left"
  }, "Nine-digit number that identifies a citizen or resident of the United States.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Society for Worldwide Interbank Financial Telecommunication (SWIFT) code")), React.createElement(_components.td, {
    align: "left"
  }, "Code that helps overseas banks identify which bank to send money to. Also known as a ", React.createElement(_components.a, {
    href: "#b"
  }, "BIC code"), ".")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Software Development Kit (SDK)")), React.createElement(_components.td, {
    align: "left"
  }, "Set of software tools and programs used by developers to create applications for specific platforms.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Standard Entry Class (SEC) Code")), React.createElement(_components.td, {
    align: "left"
  }, "Three-letter code that describes how an ", React.createElement(_components.a, {
    href: "#a"
  }, "Automated Clearing House (ACH)"), " payment is authorized.", React.createElement("br"), React.createElement("br"), "The most common ACH SEC Codes are:", React.createElement("br"), "– ", React.createElement(_components.strong, null, "CCD"), " - Corporate credit or debit transaction", React.createElement("br"), "– ", React.createElement(_components.strong, null, "TEL"), " - Telephone transaction", React.createElement("br"), "– ", React.createElement(_components.strong, null, "PPD"), " - Prearranged payment or deposit transaction", React.createElement("br"), "– ", React.createElement(_components.strong, null, "WEB"), " - Online transaction")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Sub-merchant")), React.createElement(_components.td, {
    align: "left"
  }, "Merchant customer of a ", React.createElement(_components.a, {
    href: "#p"
  }, "payment facilitator"), ".")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Subscription")), React.createElement(_components.td, {
    align: "left"
  }, "We use a subscription to add a customer to a ", React.createElement(_components.a, {
    href: "#p"
  }, "payment plan"), ".")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Surcharge")), React.createElement(_components.td, {
    align: "left"
  }, "Fee that the ", React.createElement(_components.a, {
    href: "#p"
  }, "payment processor"), " charges per transaction for credit card and debit card processing.")))), "\n", React.createElement(_components.h2, null, "T"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Tax Identification Number (TIN)")), React.createElement(_components.td, {
    align: "left"
  }, "Unique nine-digit number that the Internal Revenue Service assigns to business identities operating in the U.S. Also known as ", React.createElement(_components.a, {
    href: "#e"
  }, "Employer Identification Number (EIN)"), ".")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Terminal ID (TID)")), React.createElement(_components.td, {
    align: "left"
  }, "Unique identifier of the device that initiates a payment transaction.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Third Party Payment Processor (TPPP)")), React.createElement(_components.td, {
    align: "left"
  }, "Company that has a merchant account with a processor but allows ", React.createElement(_components.a, {
    href: "#s"
  }, "sub-merchants"), " to use their account to accept credit and debit cards. Also known as an aggregator.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Token")), React.createElement(_components.td, {
    align: "left"
  }, "Unique identifier that represents card details or bank account details. Tokens are specific to the entity that stored them and cannot be used elsewhere if they are stolen.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Tokenization")), React.createElement(_components.td, {
    align: "left"
  }, "Tokenization is the process by which a financial entity saves card details or bank account details in a vault. The financial entity creates a token that represents the payment details in the vault and can be used in future payments.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Transaction")), React.createElement(_components.td, {
    align: "left"
  }, "Any instance where goods are exchanged for money.")))), "\n", React.createElement(_components.h2, null, "U"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Uniform resource identifier (URI)")), React.createElement(_components.td, {
    align: "left"
  }, "Identifies an internet resource. Different to a ", React.createElement(_components.a, {
    href: "#u"
  }, "Uniform Resource Locator (URL)"), ", which identifies and locates an internet resource.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Uniform resource locator (URL)")), React.createElement(_components.td, {
    align: "left"
  }, "Identifies and locates an internet resource.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Union Card")), React.createElement(_components.td, {
    align: "left"
  }, "Main credit network in China.")))), "\n", React.createElement(_components.h2, null, "V"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "V/MC/D/AE")), React.createElement(_components.td, {
    align: "left"
  }, "Visa, MasterCard, Discover, American Express. The main credit card networks in the U.S.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Virtual Terminal")), React.createElement(_components.td, {
    align: "left"
  }, "Website application that allows merchants to accept payments.")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Voyager")), React.createElement(_components.td, {
    align: "left"
  }, "U.S. fuel card.")))), "\n", React.createElement(_components.h2, null, "W"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "Wright Express (WEX)")), React.createElement(_components.td, {
    align: "left"
  }, "Commerce platform with several business interests:")), React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }, React.createElement(_components.strong, null, "WorldPay")), React.createElement(_components.td, {
    align: "left"
  }, "Payment processor.")))), "\n", React.createElement(_components.h2, null, "X"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }), React.createElement(_components.td, {
    align: "left"
  })))), "\n", React.createElement(_components.h2, null, "Y"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }), React.createElement(_components.td, {
    align: "left"
  })))), "\n", React.createElement(_components.h2, null, "Z"), "\n", React.createElement(_components.table, null, React.createElement(_components.thead, null, React.createElement(_components.tr, null, React.createElement(_components.th, {
    align: "left"
  }, "Term"), React.createElement(_components.th, {
    align: "left"
  }, "Definition"))), React.createElement(_components.tbody, null, React.createElement(_components.tr, null, React.createElement(_components.td, {
    align: "left"
  }), React.createElement(_components.td, {
    align: "left"
  })))));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
